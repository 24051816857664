<template>
  <Stepper
    :subtitle="$tc('FEED.TITLE', selected.length).toUpperCase()"
    :steps="steps"
    :show="show"
    :icon="$t('FEED.ICON')"
    @close="v => $emit('close', v)"
    @create="v => $emit('create', v)"
  />
</template>
<script>
import Stepper from '@/components/Stepper'

export default {
  name: "FormFeedStepper",
  components: {
    Stepper
  },
  data: (_this) => ({
    steps: [
      {required: false, title: _this.$i18n.t('FEED.STEP_INTRO_TITLE'), description: _this.$i18n.t('FEED.STEP_INTRO_DESCRIPTION'), component: 'forms/feed/stepper/FormFeedStepper.intro'},
      {required: false, title: _this.$i18n.t('FEED.STEP_PROTOCOL_TITLE'), description: _this.$i18n.t('FEED.STEP_PROTOCOL_DESCRIPTION'), component: 'forms/feed/stepper/FormFeedStepper.protocol'},
      {required: false, title: _this.$i18n.t('FEED.STEP_FIELD_TITLE'), description: _this.$i18n.t('FEED.STEP_FIELD_DESCRIPTION'), component: 'forms/feed/stepper/FormFeedStepper.fields'},
      {required: false, title: _this.$i18n.t('FEED.STEP_CUSTOM_FIELD_TITLE'), description: _this.$i18n.t('FEED.STEP_CUSTOM_FIELD_DESCRIPTION'), component: 'forms/feed/stepper/FormFeedStepper.custom_fields'},
      {required: false, title: _this.$i18n.t('FEED.STEP_HOOK_TITLE'), description: _this.$i18n.t('FEED.STEP_HOOK_DESCRIPTION'), component: 'forms/feed/stepper/FormFeedStepper.hooks'},
      {required: false, title: _this.$i18n.t('FEED.STEP_CONFIRM_TITLE'), description: _this.$i18n.t('FEED.STEP_CONFIRM_DESCRIPTION'), component: 'forms/feed/stepper/FormFeedStepper.confirm'},
    ],
  }),
  props: {
    show: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => ([])
    }
  }
}

</script>
